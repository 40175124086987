import React, { useMemo } from "react"
import { Result } from "antd"
import { CloseCircleFilled } from "@ant-design/icons"
import { useTheme } from "../../hooks/useTheme"

function LoanDeclineView({ loan }) {
  const { colors } = useTheme()

  const subTitle = useMemo(() => {
    switch (loan?.originator?.key) {
      case "citizens":
        return "We are unable to approve your application."
      case "flexshopper":
        return "A letter will be sent to the address on your application with additional details"
      case "flexshopper-external":
        return "A letter will be sent to the address on your application with additional details"
      default:
        return "Unfortunately, your account has been declined"
    }
  }, [loan])

  const title = useMemo(() => {
    switch (loan.merchant.code) {
      case "flexshopper":
        return "Your application was unable to be approved"
      default:
        return "Declined"
    }
  }, [loan])

  const description = useMemo(() => {
    if (loan?.originator?.key === "citizens") {
      return (
        <div>
          <div>Thank you for considering Citizens Pay.</div>
          <div style={{ marginTop: 15 }}>
            You will receive an email/text when your detailed decision notice is
            available to be viewed at cpaydocuments.citizensbank.com.
          </div>
          <div style={{ marginTop: 15 }}>
            If you do not hear from us within 30 days, or have any further
            questions, please call us at 888-522-9881.
          </div>
          <div style={{ marginTop: 15 }}>
            <strong>Please Note:</strong> For your privacy,{" "}
            {loan.merchant.code === "trek"
              ? "Trek Bicycle  Corporation"
              : "the merchant"}{" "}
            does not have any information regarding this Citizens Pay
            application.
          </div>
        </div>
      )
    }
    return (
      <h5>
        If you did not receive offers you will receive an Adverse Action
        Notice via email or in the mail, from the Payment Solution Providers
        that did not offer you an approval. An Adverse Action Notice will detail
        your true credit score and the reason you receive a status of 'No
        Offers.' If you did not receive one for some reason please check your
        spam folder, or you can contact the Payment Solution Providers directly.
      </h5>
    )
  }, [loan])

  return (
    <Result
      status="error"
      title={title}
      subTitle={subTitle}
      icon={<CloseCircleFilled style={{ fontSize: 64, color: colors.red }} />}
      style={{ padding: 10 }}
    >
      <div style={{ textAlign: "center" }}>{description}</div>
    </Result>
  )
}
export default LoanDeclineView
